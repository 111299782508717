'use strict';

document.addEventListener("DOMContentLoaded", function () {
    window.favouritesMenu = new FavouritesMenu(document.querySelector('[data-favourites-menu]'));
});

function FavouritesMenu(elem) {
    this.elem = elem;
    var self = this;
    var isOpen = false;
    var culture = elem.getAttribute('data-favourites-menu-culture');
    var favList = elem.querySelector('[data-favourites-menu]');
    var favListElm = elem.querySelector('[data-favourites-menu__fav-list]');
    var clearListBtn = elem.querySelector('[data-favourites-menu__clear-list]');
    var printListBtn = elem.querySelector('[data-favourites-menu__print-list]');
    var closeListBtn = elem.querySelector('.favourites-menu__close');
    var overlay = document.querySelector('.favourite-overlay');

    function initialize() {
        if (!window.favouritesService) {
            return;
        }

        fetchAndRender();
        window.favouritesService.addOnChangeHandler(fetchAndRender);

        clearListBtn.addEventListener('click', clearList);
        printListBtn.addEventListener('click', printList);
    }
    initialize();

    this.toggle = function() {
        isOpen ? self.close() : self.open();
    }

    this.open = function () {
        isOpen = true;
        self.elem.classList.add('favourites-menu--open');
        overlay.classList.add('favourite-overlay--open');
        document.body.style.overflowY = 'hidden';
    }

    this.close = function () {
        isOpen = false;
        self.elem.classList.remove('favourites-menu--open');
        overlay.classList.remove('favourite-overlay--open');
        document.body.style.overflowY = 'unset';
    }

    closeListBtn.addEventListener('click', this.close);

    //window.addEventListener('click', function () {
    //    console.log("window is clicked");

    //    var parentDiv = document.querySelector(".header__menus");
    //    var childDiv = document.querySelector(".favourites-menu--open");
    //    if (parentDiv.contains(childDiv)) {
    //        console.log("fav er åben");

    //    } else {
    //        console.log("fav er ikke åben");
    //    }

    //});

    function clearList() {
        window.favouritesService.clearAllFavourites(function (cleared) {
            if (cleared) {
                favListElm.innerHTML = '';
            }
        });
    }

    function printList() {
        window.favouritesService.getAllFavourites(function (result) {
            var ids = '';

            for (var i = 0; i < result.length; i++) {
                ids += result[i];

                if (i < result.length - 1) {
                    ids += ',';
                }
            }

            printPage('/umbraco/surface/PrintMethods/index?ids=' + ids);
        });
    }

    function fetchAndRender() {
        window.favouritesService.getAllFavourites(function (result) {

            if (result.length == 0) {
                renderFavourites('');
                return;
            }

            var ids = '';
            for (var i = 0; i < result.length; i++) {
                ids += result[i];

                if (i < result.length - 1) {
                    ids += ',';
                }
            }

            fetchFavourites(ids);
        });
    }

    function fetchFavourites(favouritesIds) {
        window.methodsService.fetchMethodsByIds(favouritesIds, "small-12 medium-6", culture, function (result) {
            renderFavourites(result.Content);
        });
    }

    function renderFavourites(favMethodsMarkup) {
        favListElm.innerHTML = favMethodsMarkup;
        window.initializeFavoritizeButtons();
    }

    function closePrint() {
        document.body.removeChild(this.__container__);
    }

    function setPrint() {
        this.contentWindow.__container__ = this;
        this.contentWindow.onbeforeunload = closePrint;
        this.contentWindow.onafterprint = closePrint;
        this.contentWindow.focus(); // Required for IE
        this.contentWindow.print();
    }

    function printPage(sURL) {
        var oHideFrame = document.createElement("iframe");
        oHideFrame.onload = setPrint;
        oHideFrame.style.position = "fixed";
        oHideFrame.style.right = "0";
        oHideFrame.style.bottom = "0";
        oHideFrame.style.width = "0";
        oHideFrame.style.height = "0";
        oHideFrame.style.border = "0";
        oHideFrame.src = sURL;
        document.body.appendChild(oHideFrame);
    }
}